import axios from 'axios';

const tokenFace = 'EAACFmqZBPxooBOzk9vFuvQGE4lGEUSGBkHwsCb7cCPS7KaRptiGWRUIaBoEBiwX1xLUyGmYvnS0KgZC3GwdGyRFHcoh6ugqDfksZB5kEx8Li2WmruFdV3sGWIEYrQIZAXHC5zNFlsG72H3FCUNLKStDnrzbcvNBNqFBq3XGRcfv8GamVX8H56zXvsRZBUvtkb5ynEOv6xCc0ccgcZD';
const tokenInsta = 'IGQWRQUUVsUlJKamgzeE9LSlI1eHNOa0wxcHZAGcXp2eXZA3a1lBZAzA2N0dMRDhPZATZAKVVFRZATdDN0k5ZAlI4YUI5R0RERXlaLW1wcUZAxcVIzV3JCTlVJSDZAwWjZAHVVJ2OUZAFV25VbE1lVGdfSlZAHU0lDZATVhczBGbjQZD';

export const apiFacebook = axios.create({
  baseURL: 'https://graph.facebook.com/v9.0/me?fields=id%2Cname%2Clive_videos%7Bid%2Ctitle%2Cdescription%2Cstatus%2Cstream_url%2Csecure_stream_url%2Cembed_html%2Cvideo%7D&access_token='+tokenFace
});

export const apiInstagram = axios.create({
  baseURL: 'https://graph.instagram.com/me/media?fields=id,username,timestamp,media_type,caption,media_url,thumbnail_url,permalink&access_token='+tokenInsta
});
